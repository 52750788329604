import "./main.css";
import { jsxs as b, Fragment as w, jsx as r } from "react/jsx-runtime";
import { useState as N, createContext as Q, useContext as X, useCallback as Z, useEffect as ee, useMemo as S, forwardRef as te, Fragment as ne, useId as pe } from "react";
import { Modal as be, Button as x, Popover as ye, FormGroup as re, ValidatedOptions as I, Select as le, SelectVariant as Ce, SelectOption as ae, Switch as Ie, TextInput as se, TextArea as ve, NumberInput as Te, AlertGroup as Se, Alert as ke, AlertVariant as L, AlertActionCloseButton as xe, InputGroup as oe, ButtonVariant as G, Checkbox as Ne, Radio as Oe, Text as Ae, Title as Ve, Card as qe, CardHeader as we, CardTitle as Fe, CardBody as _e, Grid as Le, GridItem as B, PageSection as Re, JumpLinks as De, JumpLinksItem as Pe } from "@patternfly/react-core";
import { useFormContext as M, Controller as O, useController as ie, FormProvider as Me, useWatch as Ee } from "react-hook-form";
import { HelpIcon as j, CubeIcon as $e, PaypalIcon as He, InstagramIcon as Ge, BitbucketIcon as Be, MicrosoftIcon as je, TwitterIcon as Ue, StackOverflowIcon as Je, OpenshiftIcon as We, LinkedinIcon as Ke, GoogleIcon as ze, GitlabIcon as Ye, FacebookSquareIcon as Qe, GithubIcon as Xe, MinusCircleIcon as Ze, PlusCircleIcon as et } from "@patternfly/react-icons";
import { get as U } from "lodash-es";
const Dt = ({
  modalTitle: e,
  continueLabel: t,
  cancelLabel: n,
  buttonTitle: a,
  isDisabled: l,
  buttonVariant: s,
  onContinue: o,
  component: i = x,
  children: u,
  ...d
}) => {
  const [g, f] = N(!1);
  return /* @__PURE__ */ b(w, { children: [
    /* @__PURE__ */ r(
      i,
      {
        variant: s,
        onClick: () => f(!0),
        isDisabled: l,
        children: a
      }
    ),
    /* @__PURE__ */ r(
      be,
      {
        variant: "small",
        ...d,
        title: e,
        isOpen: g,
        onClose: () => f(!1),
        actions: [
          /* @__PURE__ */ r(
            x,
            {
              id: "modal-confirm",
              variant: "primary",
              onClick: () => {
                f(!1), o();
              },
              children: t
            },
            "confirm"
          ),
          /* @__PURE__ */ r(
            x,
            {
              id: "modal-cancel",
              variant: "secondary",
              onClick: () => f(!1),
              children: n
            },
            "cancel"
          )
        ],
        children: u
      }
    )
  ] });
};
function tt(e, t) {
  const n = Q(t);
  return n.displayName = e, n;
}
function nt(e) {
  return e != null;
}
function rt(e) {
  const t = X(e);
  if (nt(t))
    return t;
  throw new Error(
    `No provider found for ${e.displayName ? `the '${e.displayName}'` : "an unknown"} context, make sure it is included in your component hierarchy.`
  );
}
function lt(e, t, n) {
  const [a, l] = N(
    () => e.getItem(t) ?? n
  ), s = Z((o) => {
    l(o), e.setItem(t, o);
  }, []);
  return ee(() => {
    l(e.getItem(t) ?? n), window.addEventListener("storage", o);
    function o(i) {
      i.storageArea === e && (i.key === null || i.key === t) && l(i.newValue ?? n);
    }
    return () => window.removeEventListener("storage", o);
  }, [e, t]), [a, s];
}
function at(e, t, n) {
  const a = S(
    () => JSON.stringify(n),
    [n]
  ), [l, s] = lt(
    e,
    t,
    a
  ), o = S(() => JSON.parse(l), [l]), i = Z(
    (u) => s(JSON.stringify(u)),
    []
  );
  return [o, i];
}
const ce = tt(
  "HelpContext",
  void 0
), st = () => rt(ce), Pt = ({ children: e }) => {
  const [t, n] = at(localStorage, "helpEnabled", !0);
  function a() {
    n(!t);
  }
  return /* @__PURE__ */ r(ce.Provider, { value: { enabled: t, toggleHelp: a }, children: e });
}, ue = ({
  helpText: e,
  fieldLabelId: t,
  noVerticalAlign: n = !0,
  unWrap: a = !1
}) => {
  const { enabled: l } = st();
  return l ? /* @__PURE__ */ r(ye, { bodyContent: e, children: /* @__PURE__ */ b(w, { children: [
    !a && /* @__PURE__ */ r(
      "button",
      {
        "data-testid": `help-label-${t}`,
        "aria-label": t,
        onClick: (s) => s.preventDefault(),
        className: "pf-c-form__group-label-help",
        children: /* @__PURE__ */ r(j, { noVerticalAlign: n })
      }
    ),
    a && /* @__PURE__ */ r(j, { noVerticalAlign: n })
  ] }) }) : null;
}, A = ({
  name: e,
  label: t,
  labelIcon: n,
  error: a,
  children: l,
  ...s
}) => /* @__PURE__ */ r(
  re,
  {
    label: t || e,
    fieldId: e,
    labelIcon: n ? /* @__PURE__ */ r(ue, { helpText: n, fieldLabelId: e }) : void 0,
    helperTextInvalid: a?.message,
    validated: a ? I.error : I.default,
    ...s,
    children: l
  }
), ot = ({
  name: e,
  label: t,
  options: n,
  controller: a,
  variant: l,
  labelIcon: s,
  ...o
}) => {
  const {
    control: i,
    formState: { errors: u }
  } = M(), [d, g] = N(!1);
  return /* @__PURE__ */ r(
    A,
    {
      name: e,
      label: t,
      isRequired: a.rules?.required === !0,
      error: u[e],
      labelIcon: s,
      children: /* @__PURE__ */ r(
        O,
        {
          ...a,
          name: e,
          control: i,
          render: ({ field: { onChange: f, value: h } }) => /* @__PURE__ */ r(
            le,
            {
              ...o,
              toggleId: e.slice(e.lastIndexOf(".") + 1),
              onToggle: (c) => g(c),
              selections: typeof n[0] != "string" ? n.filter((c) => h.includes(c.key)).map((c) => c.value) : h,
              onSelect: (c, m) => {
                if (l === "typeaheadmulti") {
                  const v = m.toString();
                  h.includes(v) ? f(h.filter((k) => k !== v)) : f([...h, v]);
                } else
                  f(m), g(!1);
              },
              onClear: l !== Ce.single ? (c) => {
                c.stopPropagation(), f([]);
              } : void 0,
              isOpen: d,
              variant: l,
              validated: u[e] ? I.error : I.default,
              children: n.map((c) => /* @__PURE__ */ r(
                ae,
                {
                  value: typeof c == "string" ? c : c.key,
                  children: typeof c == "string" ? c : c.value
                },
                typeof c == "string" ? c : c.key
              ))
            }
          )
        }
      )
    }
  );
}, Mt = (e) => {
  const t = e.defaultValue ?? !1, { control: n } = M();
  return /* @__PURE__ */ r(
    A,
    {
      hasNoPaddingTop: !0,
      name: e.name,
      isRequired: e.rules?.required === !0,
      label: e.label,
      labelIcon: e.labelIcon,
      children: /* @__PURE__ */ r(
        O,
        {
          control: n,
          name: e.name,
          defaultValue: t,
          render: ({ field: { onChange: a, value: l } }) => /* @__PURE__ */ r(
            Ie,
            {
              id: e.name,
              "data-testid": e.name,
              label: e.labelOn,
              labelOff: e.labelOff,
              isChecked: e.stringify ? l === "true" : l,
              onChange: (s, o) => {
                const i = e.stringify ? s.toString() : s;
                e.onChange?.(s, o), a(i);
              }
            }
          )
        }
      )
    }
  );
}, E = te(({ onChange: e, ...t }, n) => /* @__PURE__ */ r(se, { ...t, ref: n, onChange: (l, s) => e?.(s) }));
E.displayName = "TextInput";
const Et = (e) => {
  const { labelIcon: t, ...n } = e, a = !!e.rules?.required, l = e.defaultValue ?? "", { field: s, fieldState: o } = ie({
    ...e,
    defaultValue: l
  });
  return /* @__PURE__ */ r(
    A,
    {
      name: e.name,
      label: e.label,
      labelIcon: t,
      isRequired: a,
      error: o.error,
      children: /* @__PURE__ */ r(
        E,
        {
          isRequired: a,
          id: e.name,
          "data-testid": e.name,
          validated: o.error ? I.error : I.default,
          isDisabled: e.isDisabled,
          ...n,
          ...s
        }
      )
    }
  );
}, $ = te(({ onChange: e, ...t }, n) => /* @__PURE__ */ r(ve, { ...t, ref: n, onChange: (l, s) => e?.(s) }));
$.displayName = "TextArea";
const $t = (e) => {
  const t = !!e.rules?.required, n = e.defaultValue ?? "", { field: a, fieldState: l } = ie({
    ...e,
    defaultValue: n
  });
  return /* @__PURE__ */ r(
    A,
    {
      isRequired: t,
      label: e.label,
      labelIcon: e.labelIcon,
      name: e.name,
      error: l.error,
      children: /* @__PURE__ */ r(
        $,
        {
          isRequired: t,
          id: e.name,
          "data-testid": e.name,
          validated: l.error ? I.error : I.default,
          isDisabled: e.isDisabled,
          ...a
        }
      )
    }
  );
}, Ht = ({
  name: e,
  label: t,
  controller: n,
  labelIcon: a,
  ...l
}) => {
  const {
    control: s,
    formState: { errors: o }
  } = M();
  return /* @__PURE__ */ r(
    A,
    {
      name: e,
      label: t,
      isRequired: n.rules?.required === !0,
      error: o[e],
      labelIcon: a,
      children: /* @__PURE__ */ r(
        O,
        {
          ...n,
          name: e,
          control: s,
          render: ({ field: i }) => {
            const u = !!n.rules?.required, d = n.rules?.min, g = i.value === 0 ? n.defaultValue : i.value, f = (h) => i.onChange(d ? Math.max(h, Number(d)) : h);
            return /* @__PURE__ */ r(
              Te,
              {
                ...l,
                id: e,
                value: g,
                validated: o[e] ? I.error : I.default,
                required: u,
                min: Number(d),
                max: Number(n.rules?.max),
                onPlus: () => f(g + 1),
                onMinus: () => f(g - 1),
                onChange: (h) => {
                  const c = Number(h.currentTarget.value);
                  f(isNaN(c) ? n.defaultValue : c);
                }
              }
            );
          }
        }
      )
    }
  );
}, de = Q(void 0), Gt = () => X(de), Bt = ({ children: e }) => {
  const [t, n] = N([]), a = (o) => {
    n((i) => i.filter((u) => u.id !== o));
  }, l = (o, i = L.success, u) => {
    n([
      {
        id: Math.random() * 100,
        message: o,
        variant: i,
        description: u
      },
      ...t
    ]);
  }, s = (o) => {
    l(o, L.danger);
  };
  return /* @__PURE__ */ b(de.Provider, { value: { addAlert: l, addError: s }, children: [
    /* @__PURE__ */ r(Se, { isToast: !0, "data-testid": "alerts", children: t.map(({ id: o, variant: i, message: u, description: d }) => /* @__PURE__ */ r(
      ke,
      {
        isLiveRegion: !0,
        variant: L[i],
        variantLabel: "",
        title: u,
        actionClose: /* @__PURE__ */ r(
          xe,
          {
            title: u,
            onClose: () => a(o)
          }
        ),
        timeout: !0,
        onTimeout: () => a(o),
        children: d && /* @__PURE__ */ r("p", { children: d })
      },
      o
    )) }),
    e
  ] });
}, jt = ({ icon: e }) => {
  const t = it(e);
  return /* @__PURE__ */ r(t, { size: "lg", alt: e });
};
function it(e) {
  switch (e) {
    case "github":
      return Xe;
    case "facebook":
      return Qe;
    case "gitlab":
      return Ye;
    case "google":
      return ze;
    case "linkedin":
    case "linkedin-openid-connect":
      return Ke;
    case "openshift-v3":
    case "openshift-v4":
      return We;
    case "stackoverflow":
      return Je;
    case "twitter":
      return Ue;
    case "microsoft":
      return je;
    case "bitbucket":
      return Be;
    case "instagram":
      return Ge;
    case "paypal":
      return He;
    default:
      return $e;
  }
}
const ct = (e) => {
  try {
    return new Intl.DisplayNames([e], { type: "language" }).of(e);
  } catch {
    return e;
  }
}, ut = ({
  t: e,
  form: t,
  supportedLocales: n
}) => {
  const a = n.map((l) => ({
    key: l,
    value: ct(l) || ""
  }));
  return a.length ? /* @__PURE__ */ r(Me, { ...t, children: /* @__PURE__ */ r(
    ot,
    {
      "data-testid": "locale-select",
      name: "attributes.locale",
      label: e("selectALocale"),
      controller: { defaultValue: "" },
      options: a
    }
  ) }) : null;
}, me = (e) => e?.includes("${"), H = (e) => e.substring(2, e.length - 1), R = (e, t, n) => (me(t) ? e(H(t)) : t) || n, D = (e, t) => R(e, t.displayName, t.name), dt = ["username", "firstName", "lastName", "email"], fe = (e) => e && dt.includes(e), T = (e) => `${fe(e) ? "" : "attributes."}${e?.replaceAll(
  ".",
  "🍺"
)}`, Ut = (e) => e.replaceAll("🍺", ".");
function Jt(e, t, n) {
  (e.responseData.errors !== void 0 ? e.responseData.errors : [e.responseData]).forEach((a) => {
    const l = Object.assign(
      {},
      a.params?.map((s) => n(me(s.toString()) ? H(s) : s))
    );
    t(T(a.field), {
      message: n(a.errorMessage, {
        ...l,
        defaultValue: a.errorMessage || a.field
      }),
      type: "server"
    });
  });
}
function V({
  required: e,
  validators: t
}) {
  return e || mt(t);
}
function mt(e) {
  return e && "length" in e && "min" in e.length && typeof e.length.min == "number" ? e.length.min > 0 : !1;
}
function Wt(e) {
  if (typeof e != "object" || e === null || !("responseData" in e))
    return !1;
  const { responseData: t } = e;
  return J(t) ? !0 : typeof t != "object" || t === null || !("errors" in t) || !Array.isArray(t.errors) ? !1 : t.errors.every(J);
}
function J(e) {
  return !(typeof e != "object" || e === null || !("field" in e) || typeof e.field != "string" || !("errorMessage" in e) || typeof e.errorMessage != "string");
}
const q = ({
  t: e,
  form: t,
  attribute: n,
  renderer: a,
  children: l
}) => {
  const s = n.annotations?.inputHelperTextBefore, {
    formState: { errors: o }
  } = t, i = a?.(n);
  return /* @__PURE__ */ r(
    re,
    {
      label: D(e, n) || "",
      fieldId: n.name,
      isRequired: V(n),
      validated: U(o, T(n.name)) ? "error" : "default",
      helperTextInvalid: e(
        U(o, T(n.name))?.message
      ),
      labelIcon: s ? /* @__PURE__ */ r(ue, { helpText: s, fieldLabelId: n.name }) : void 0,
      children: i ? /* @__PURE__ */ b(oe, { children: [
        l,
        i
      ] }) : l
    },
    n.name
  );
}, ft = ({
  t: e,
  form: t,
  attribute: n,
  renderer: a,
  ...l
}) => /* @__PURE__ */ r(q, { t: e, form: t, attribute: n, renderer: a, children: /* @__PURE__ */ r(
  ht,
  {
    t: e,
    form: t,
    "aria-label": D(e, n),
    name: T(n.name),
    addButtonLabel: e("addMultivaluedLabel", {
      fieldLabel: D(e, n)
    }),
    ...l
  }
) }), ht = ({
  t: e,
  name: t,
  inputType: n,
  form: a,
  addButtonLabel: l,
  isDisabled: s = !1,
  defaultValue: o,
  id: i,
  ...u
}) => {
  const { register: d, setValue: g, control: f } = a, h = Ee({
    name: t,
    control: f,
    defaultValue: o || ""
  }), c = S(() => Array.isArray(h) && h.length !== 0 ? h : o || [""], [h]), m = (y) => {
    F([...c.slice(0, y), ...c.slice(y + 1)]);
  }, v = () => {
    F([...c, ""]);
  }, k = (y, p) => {
    F([...c.slice(0, y), p, ...c.slice(y + 1)]);
  }, F = (y) => {
    const p = y.flatMap((_) => _);
    g(t, p, {
      shouldDirty: !0
    });
  }, ge = n.startsWith("html") ? n.substring(6) : "text";
  return ee(() => {
    d(t);
  }, [d]), /* @__PURE__ */ r("div", { id: i, children: c.map((y, p) => /* @__PURE__ */ b(ne, { children: [
    /* @__PURE__ */ b(oe, { children: [
      /* @__PURE__ */ r(
        se,
        {
          "data-testid": t + p,
          onChange: (_) => k(p, _),
          name: `${t}.${p}.value`,
          value: y,
          isDisabled: s,
          type: ge,
          ...u
        }
      ),
      /* @__PURE__ */ r(
        x,
        {
          "data-testid": "remove" + p,
          variant: G.link,
          onClick: () => m(p),
          tabIndex: -1,
          "aria-label": e("remove"),
          isDisabled: c.length === 1 || s,
          children: /* @__PURE__ */ r(Ze, {})
        }
      )
    ] }),
    p === c.length - 1 && /* @__PURE__ */ b(
      x,
      {
        variant: G.link,
        onClick: v,
        tabIndex: -1,
        "aria-label": e("add"),
        "data-testid": "addValue",
        isDisabled: !y || s,
        children: [
          /* @__PURE__ */ r(et, {}),
          " ",
          e(l || "add")
        ]
      }
    )
  ] }, p)) });
}, W = (e) => {
  const { form: t, inputType: n, attribute: a } = e, l = V(a), s = n.startsWith("multiselect"), o = s ? Ne : Oe, i = a.validators?.options?.options || [];
  return /* @__PURE__ */ r(q, { ...e, children: /* @__PURE__ */ r(
    O,
    {
      name: T(a.name),
      control: t.control,
      defaultValue: "",
      render: ({ field: u }) => /* @__PURE__ */ r(w, { children: i.map((d) => /* @__PURE__ */ r(
        o,
        {
          id: d,
          "data-testid": d,
          label: d,
          value: d,
          isChecked: u.value.includes(d),
          onChange: () => {
            s ? u.value.includes(d) ? u.onChange(
              u.value.filter((g) => g !== d)
            ) : u.onChange([...u.value, d]) : u.onChange([d]);
          },
          readOnly: a.readOnly,
          isRequired: l
        },
        d
      )) })
    }
  ) });
}, K = (e) => {
  const { t, form: n, inputType: a, attribute: l } = e, [s, o] = N(!1), i = V(l), u = a === "multiselect", d = (c, m) => {
    u ? m.value.includes(c) ? m.onChange(m.value.filter((v) => v !== c)) : m.onChange([...m.value, c]) : m.onChange(c);
  }, g = l.validators?.options?.options || [], f = l.annotations?.inputOptionLabels, h = (c) => f ? t(H(f[c])) : c;
  return /* @__PURE__ */ r(q, { ...e, children: /* @__PURE__ */ r(
    O,
    {
      name: T(l.name),
      defaultValue: "",
      control: n.control,
      render: ({ field: c }) => /* @__PURE__ */ r(
        le,
        {
          toggleId: l.name,
          onToggle: (m) => o(m),
          isCreatable: !0,
          onCreateOption: (m) => d(m, c),
          onSelect: (m, v) => {
            const k = v.toString();
            d(k, c), Array.isArray(c.value) || o(!1);
          },
          selections: c.value ? c.value : u ? [] : t("choose"),
          variant: u ? "typeaheadmulti" : "single",
          "aria-label": t("selectOne"),
          isOpen: s,
          isDisabled: l.readOnly,
          required: i,
          children: ["", ...g].map((m) => /* @__PURE__ */ r(
            ae,
            {
              selected: c.value === m,
              value: m,
              children: m ? h(m) : t("choose")
            },
            m
          ))
        }
      )
    }
  ) });
}, gt = (e) => {
  const { form: t, attribute: n } = e, a = V(n);
  return /* @__PURE__ */ r(q, { ...e, children: /* @__PURE__ */ r(
    $,
    {
      id: n.name,
      "data-testid": n.name,
      ...t.register(T(n.name)),
      cols: n.annotations?.inputTypeCols,
      rows: n.annotations?.inputTypeRows,
      readOnly: n.readOnly,
      isRequired: a
    }
  ) });
}, C = (e) => {
  const { form: t, inputType: n, attribute: a } = e, l = V(a), s = n.startsWith("html") ? n.substring(6) : "text";
  return /* @__PURE__ */ r(q, { ...e, children: /* @__PURE__ */ r(
    E,
    {
      id: a.name,
      "data-testid": a.name,
      type: s,
      placeholder: a.annotations?.inputTypePlaceholder,
      readOnly: a.readOnly,
      isRequired: l,
      ...t.register(T(a.name))
    }
  ) });
}, P = {
  text: C,
  textarea: gt,
  select: K,
  "select-radiobuttons": W,
  multiselect: K,
  "multiselect-checkboxes": W,
  "html5-email": C,
  "html5-tel": C,
  "html5-url": C,
  "html5-number": C,
  "html5-range": C,
  "html5-datetime-local": C,
  "html5-date": C,
  "html5-month": C,
  "html5-time": C,
  "multi-input": ft
}, Kt = ({
  t: e,
  form: t,
  userProfileMetadata: n,
  supportedLocales: a,
  hideReadOnly: l = !1,
  renderer: s
}) => {
  const o = S(() => {
    if (!n.attributes)
      return [];
    const i = l ? n.attributes.filter(({ readOnly: u }) => !u) : n.attributes;
    return [
      // Insert an empty group for attributes without a group.
      { name: void 0 },
      ...n.groups ?? []
    ].map((u) => ({
      group: u,
      attributes: i.filter(
        (d) => d.group === u.name
      )
    }));
  }, [
    l,
    n.groups,
    n.attributes
  ]);
  return o.length === 0 ? null : /* @__PURE__ */ r(
    At,
    {
      label: e("jumpToSection"),
      sections: o.filter((i) => i.attributes.length > 0).map(({ group: i, attributes: u }) => ({
        title: R(e, i.displayHeader, i.name) || e("general"),
        panel: /* @__PURE__ */ b("div", { className: "pf-c-form", children: [
          i.displayDescription && /* @__PURE__ */ r(Ae, { className: "pf-u-pb-lg", children: R(e, i.displayDescription, "") }),
          u.map((d) => /* @__PURE__ */ r(
            pt,
            {
              t: e,
              form: t,
              supportedLocales: a,
              renderer: s,
              attribute: d
            },
            d.name
          ))
        ] })
      }))
    }
  );
}, pt = ({
  t: e,
  form: t,
  renderer: n,
  supportedLocales: a,
  attribute: l
}) => {
  const s = t.watch(
    T(l.name)
  ), o = S(() => yt(l), [l]), i = l.multivalued || It(s) ? P["multi-input"] : P[o];
  return l.name === "locale" ? /* @__PURE__ */ r(
    ut,
    {
      form: t,
      supportedLocales: a,
      t: e,
      attribute: l
    }
  ) : /* @__PURE__ */ r(
    i,
    {
      t: e,
      form: t,
      inputType: o,
      attribute: l,
      renderer: n
    }
  );
}, bt = "text";
function yt(e) {
  if (fe(e.name))
    return "text";
  const t = e.annotations?.inputType;
  return Ct(t) ? t : bt;
}
const Ct = (e) => typeof e == "string" && e in P, It = (e) => Array.isArray(e) && e.length > 1, vt = "_title_1nyfo_2", Tt = {
  title: vt
}, he = ({
  id: e,
  title: t,
  headingLevel: n = "h1",
  size: a = "xl",
  ...l
}) => /* @__PURE__ */ r(
  Ve,
  {
    headingLevel: n,
    size: a,
    className: Tt.title,
    id: e,
    tabIndex: 0,
    ...l,
    children: t
  }
), St = ({
  title: e,
  children: t,
  scrollId: n,
  className: a
}) => {
  const l = pe();
  return /* @__PURE__ */ b(qe, { id: l, className: a, isFlat: !0, children: [
    /* @__PURE__ */ r(we, { className: "kc-form-panel__header", children: /* @__PURE__ */ r(Fe, { tabIndex: 0, children: /* @__PURE__ */ r(he, { id: n, title: e }) }) }),
    /* @__PURE__ */ r(_e, { className: "kc-form-panel__body", children: t })
  ] });
}, kt = (e) => {
  const { title: t, children: n, scrollId: a, ...l } = e;
  return /* @__PURE__ */ r("section", { ...l, style: { marginTop: "var(--pf-global--spacer--lg)" }, children: /* @__PURE__ */ b(w, { children: [
    /* @__PURE__ */ r(he, { id: a, title: t }),
    n
  ] }) });
}, xt = "_panel_cd9gh_1", Nt = "_sticky_cd9gh_5", z = {
  panel: xt,
  sticky: Nt
}, Ot = "kc-main-content-page-container", Y = (e) => e.replace(/\s+/g, "-"), At = ({
  label: e,
  sections: t,
  borders: n = !1,
  ...a
}) => {
  const l = S(
    () => t.filter(({ isHidden: s }) => !s),
    [t]
  );
  return /* @__PURE__ */ b(Le, { hasGutter: !0, ...a, children: [
    /* @__PURE__ */ r(B, { md: 8, sm: 12, children: l.map(({ title: s, panel: o }) => {
      const i = Y(s.toLowerCase());
      return /* @__PURE__ */ r(ne, { children: n ? /* @__PURE__ */ r(
        St,
        {
          scrollId: i,
          title: s,
          className: z.panel,
          children: o
        }
      ) : /* @__PURE__ */ r(kt, { scrollId: i, title: s, children: o }) }, s);
    }) }),
    /* @__PURE__ */ r(B, { md: 4, sm: 12, order: { default: "-1", md: "1" }, children: /* @__PURE__ */ r(Re, { className: z.sticky, children: /* @__PURE__ */ r(
      De,
      {
        isVertical: !0,
        scrollableSelector: `#${Ot}`,
        label: e,
        offset: 100,
        children: l.map(({ title: s }) => {
          const o = Y(s.toLowerCase());
          return (
            // note that JumpLinks currently does not work with spaces in the href
            /* @__PURE__ */ r(
              Pe,
              {
                href: `#${o}`,
                "data-testid": `jump-link-${o}`,
                children: s
              },
              s
            )
          );
        })
      }
    ) }) })
  ] });
};
export {
  Bt as AlertProvider,
  Dt as ContinueCancelModal,
  St as FormPanel,
  Pt as Help,
  ue as HelpItem,
  jt as IconMapper,
  $ as KeycloakTextArea,
  E as KeycloakTextInput,
  Ht as NumberControl,
  At as ScrollForm,
  ot as SelectControl,
  Mt as SwitchControl,
  $t as TextAreaControl,
  Et as TextControl,
  Kt as UserProfileFields,
  tt as createNamedContext,
  Ut as debeerify,
  nt as isDefined,
  Wt as isUserProfileError,
  R as label,
  Ot as mainPageContentId,
  Jt as setUserProfileServerError,
  Gt as useAlerts,
  st as useHelp,
  rt as useRequiredContext,
  at as useStoredState
};
